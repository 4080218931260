import { useState } from "react";
import BusinessTracingJsonCard from "../components/BusinessTracingJsonCard";
import InputCard from "../components/InputCard";
import { DeliveryOption } from "../models/DeliveryOptionsModel";
import { getDeliveryOptions } from "../api/TestDeliveryOptions";
import DeliveryOptionsCard from "../components/DeliveryOptionsCard";
import './TestDeliveryOptionsPage.scss';
import { getBusinessTracingEvents, TraceEvent } from "../api/BusinessTracing";
import { Alert } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

const TestDeliveryOptionsPage = (props: any) => {
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOption[] | null>(null);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [traceLoading, setTraceLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [traceEvents, setTraceEvents] = useState<TraceEvent[] | null>(null);
  const [traceId, setTraceId] = useState<string | undefined>();

  const { accounts }: any = useMsal();
  var roles = accounts[0].idTokenClaims.roles as Array<string>;
  var filteredRoles = roles.filter(x => x === "Developer");
  var developerMode = filteredRoles.length > 0;

  const handleStartLoading = () => {
    setOptionsLoading(true)
  }

  const handleStopLoading = () => {
    setOptionsLoading(false)
  }

  const handleSetError = (error: string) => {
    if (typeof error !== "string")
    {
      error = "Error happened";
    }
    setError(error)
  }

  const handleClearError = () => {
    setError(null)
  }

  const handleRefreshTrace = () => {
    getBusinessTrace()
  }

  const handleGetDeliveryOptions = async (jsonInput: string) => {
    handleStartLoading()
    handleClearError()
    setDeliveryOptions(null)
    setTraceEvents(null)
    const newTraceId = crypto.randomUUID().replaceAll('-', '');
    setTraceId(newTraceId)
    try {
      const result = await getDeliveryOptions(JSON.parse(jsonInput), newTraceId);
      const resultOptions = result.flattenedDeliveryOptions.map(
        (option: any) =>
        ({
          factoryCode: option.factoryCode,
          shipmentNumber: option.shipmentNumber,
          rowSpan: option.rowSpan,
          products: option.products,
          optionNumber: option.optionNumber,
          shipDate: option.shipDate,
          deliveryDate: option.deliveryDate,
          deliveryMethod: option.deliveryMethod,
          deliveryType: option.deliveryType,
          carrierName: option.carrierName,
          iWaysCarrierCode: option.iWaysCarrierCode,
          pudoNetworkId: option.pudoNetworkId,
          deliveryMethodWithNetwork: { method: option.deliveryMethod, networkId: option.pudoNetworkId }
        } as DeliveryOption));
      setDeliveryOptions(resultOptions)
      setTraceLoading(true)
      setTimeout(() => {
        getBusinessTrace(newTraceId);
      }, 3000);
    } catch (err: any) {
      handleSetError(getErrorMessage(err));
    } finally {
      handleStopLoading();
    }
  };

  const getBusinessTrace = async (id?: string) => {
    const currentTraceId = id ?? traceId
    if (currentTraceId) {
      try {
        setTraceLoading(true)
        const result = await getBusinessTracingEvents(currentTraceId);
        setTraceEvents(result)
        setTraceLoading(false)
      } catch (err: any) {
        handleSetError(getErrorMessage(err));
        setTraceLoading(false)
      }
    }
  }

  const getErrorMessage = (err: any) => {
    let errorMessage = err.message || "An error occurred while getting the Delivery Options";
    const error = err?.response?.data?.Message;
    if (error) {
      errorMessage = err?.response?.data?.Message;
      try {
        let errorObj = JSON.parse(errorMessage);
        errorObj = JSON.parse(errorObj.Message);
        errorMessage = errorObj.Message;
      } catch { }
    }
    else if (err?.response?.data && typeof err?.response?.data === "string") {
      errorMessage = err?.response?.data;
    }
    else if (err?.response?.data?.title && typeof err?.response?.data?.title === "string") {
      errorMessage = err?.response?.data?.title;
    }
    return errorMessage;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <InputCard
            onGetDeliveryOptions={handleGetDeliveryOptions}
            onStartLoading={handleStartLoading}
            onStopLoading={handleStopLoading}
            onSetError={handleSetError}
            onClearError={handleClearError}
          />
        </div>

        <div className="col-md-8">
          <DeliveryOptionsCard data={deliveryOptions} isLoading={optionsLoading} />
          {error && (
            <Alert variant="danger" onClose={handleClearError} dismissible>
              <Alert.Heading>{error}</Alert.Heading>
            </Alert>
          )}
          {developerMode && <BusinessTracingJsonCard traceEvents={traceEvents} onRefreshTrace={handleRefreshTrace} isLoading={traceLoading} />}
        </div>
      </div>
    </>
  );
};

export default TestDeliveryOptionsPage;
