import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { DeliveryOption } from "../models/DeliveryOptionsModel";
import moment, { MomentInput } from "moment";
//delivery methods
import PudoIcon from '../icons/icon_pudo.png';
import HomeIcon from '../icons/icon_home.png';
//delivery types
import StandardDeliveryIcon from '../icons/icon_standard.png';
import FastDeliveryIcon from '../icons/icon_fast.png';
import ExpressDeliveryIcon from '../icons/icon_express.png';


type Props = {
    cardProps?: any,
    data: DeliveryOption[] | null
    isLoading: boolean
}

const shipDateFormatter = (cell: MomentInput) => {
    if (!cell) {
        return "";
    }
    const shipDate = moment(cell).utc();
    const shippingCutoffTimeNotValid = shipDate.hour() === 0 && shipDate.minute() === 0;  //considering pickup time at midnight is not a real case
    const toolTip = shippingCutoffTimeNotValid ? "Shipping Cutoff Time not returned from Centiro. Could be wrong zipcode, or misconfiguration" : `Shipping Cutoff time: ${shipDate.format("HH:mm")}`;
    return <>{shipDate.format("YYYY-MM-DD")} <span className={shippingCutoffTimeNotValid ? "error" : "good"} title={toolTip}>{shipDate.format("HH:mm")}</span></>;
};

const deliveryDateFormatter = (cell: any, row: any) => {
    const deliveryDate = moment(cell);
    const shipDate = moment(row.shipDate);

    const dateDiff = deliveryDate.diff(shipDate, 'days');
    let className, tooltip;
    if (dateDiff < 0) {
        className = "error";
        tooltip = "DeliveryDate before Shipdate is wrong. Please check country+zipcode pair and/or shipping lead time calculation in Centiro."
    }
    else if (dateDiff > 10) {
        className = "warn";
        tooltip = "More than 10 days shipping lead time is suspicious. IF the given ZIP CODE is valid - you might want to check lead time calculation in Centiro."

    }
    return <span className={className} title={tooltip}>{cell}</span>
}

const productsFormatter = (cell: any, row: any) => {
    //consider rowSpan, return empty if rowSpan == ""
    if (row.rowSpan === "") {
        return <></>;
    }

    //cell is array of products
    return cell.map((p: any) => {
        return (<><span className={(p.skuFound ? "good" : "error")} title={p.skuFound ? `SKU: ${p.sku}` : "SKU not found. Please product options in the Request and/or SKU Mapper."}>{p.quantity}x {p.productTag}</span><br /></>);
    });
}

const deliveryOptionFormatter = (cell: any, row: any) => {
    const validCarrierCode = row.iWaysCarrierCode?.length > 0;
    const deliveryType = row.deliveryType.toLowerCase();
    let deliveryTypeIcon;
    switch (deliveryType) {
        case "express":
            deliveryTypeIcon = ExpressDeliveryIcon;
            break;
        case "fast":
            deliveryTypeIcon = FastDeliveryIcon;
            break;
        case "standard":
            deliveryTypeIcon = StandardDeliveryIcon;
            break;
        default:
            console.error(`Unsupported deliveryType: ${deliveryType}`);
            deliveryTypeIcon = "";
    }

    const deliveryMethod = row.deliveryMethod.toLowerCase();

    const pudoNetworkNotSet = row.pudoNetworkId == "*" || row.pudoNetworkId == "";
    const pudoNetworkMsg = pudoNetworkNotSet ? `PUDO NetworkId '${row.pudoNetworkId}' IS NOT VALID. Please set in Centiro Candidates` : `PUDO network: ${row.pudoNetworkId}`;
    const carrierCodeMsg = validCarrierCode ? `iWays carrier code: ${row.iWaysCarrierCode}` : "iWays Carrier code NOT SET. Please set in Centiro Candidates.";

    if (deliveryMethod === "pudo") {
        return (<>
            <img src={deliveryTypeIcon} title={row.deliveryType} className="deliveryIcon" />
            <img src={PudoIcon} title={pudoNetworkMsg} className="deliveryIcon" />
            <br />
            <span className={(pudoNetworkNotSet || !validCarrierCode ? "error" : "good")} title={carrierCodeMsg + "\r\n" + pudoNetworkMsg}>{row.carrierName}</span>
        </>);
    }
    else if (deliveryMethod === "home") {
        return (<>
            <img src={deliveryTypeIcon} title={row.deliveryType} className="deliveryIcon" />
            <img src={HomeIcon} title={row.deliveryMethod} alt="HOME" className="deliveryIcon" />
            <br />
            <span className={(validCarrierCode ? "good" : "error")} title={carrierCodeMsg}>{row.carrierName}</span>
        </>);
    }
    return `${row.deliveryType} ${row.deliveryMethod} ${row.carrierName} (${row.iWaysCarrierCode}, ${row.pudoNetworkId})`;
}

const rowSpanFormatter = (cell: any, row: any) => {
    //hide value if rowSpan == ""
    if (row.rowSpan === "") {
        return <></>;
    }

    return <b>{cell}</b>;
}

//"custom stripes" for "rowspan"
const styleStripeFormatter = (
    cell: any,
    row: any,
    rowIndex: any,
    colIndex: any
) => {
    return {
        backgroundColor: row.shipmentNumber % 2 === 0 ? '#FFFFFF' : '#EBEDF3',
        //separate packages with border - but top-border doesn't work for some reason, todo
        //borderTop: (row.rowSpan === "" ? '' : '1px solid rgba(0, 0, 0, 0.8)')
    };
};

const columns = [
    {
        dataField: "factoryCode",
        text: "Plant",
        classes: "text-left",
        headerClasses: "text-left",
        formatter: rowSpanFormatter,
        style: styleStripeFormatter
    },
    {
        dataField: "shipmentNumber",
        text: "PKG#",
        classes: "text-left",
        headerClasses: "text-left",
        formatter: rowSpanFormatter,
        style: styleStripeFormatter
    },
    {
        dataField: "products",
        text: "Products",
        classes: "text-left",
        headerClasses: "text-left",
        formatter: productsFormatter,
        style: styleStripeFormatter
    },
    {
        dataField: "optionNumber",
        text: "OPT#",
        classes: "text-left",
        headerClasses: "text-left",
    },
    {
        dataField: "shipDate",
        text: "Ship Date",
        classes: "text-left",
        headerClasses: "text-left",
        formatter: shipDateFormatter
    },
    {
        dataField: "deliveryDate",
        text: "Delivery Date",
        classes: "text-left",
        headerClasses: "text-left",
        formatter: deliveryDateFormatter
    },
    {
        dataField: "carrierName",
        text: "Delivery Option",
        classes: "text-left",
        headerClasses: "text-left",
        formatter: deliveryOptionFormatter
    }
];

const DeliveryOptionsCard = (props: Props) => {
    return (
        <Card {...props.cardProps}>
            <CardHeader title="DELIVERY OPTIONS" {...props.cardProps}></CardHeader>
            <CardBody {...props.cardProps}>
                {props.isLoading ? (
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" variant="primary"></Spinner>
                        <div>Calculating...</div>
                    </div>
                ) : (
                    <>
                        {props.data ? (
                            <BootstrapTable
                                wrapperClasses="table-responsive column-width-control"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={true}
                                striped={true}
                                remote
                                keyField="productGroup"
                                data={props.data}
                                columns={columns}
                                defaultSorted={[{ dataField: "productGroup", order: "asc" }]}
                            ></BootstrapTable>
                        ) : (
                            <div className="text-left p-5 alert-info rounded">
                                <p>No delivery options have been found yet.</p>
                                <p> Ways to <b>get</b> them:
                                    <ol>
                                        <li>By <b>OrderCode</b>: Paste order code, and click <b className="text-primary">Get Request</b>. This will fetch order details, and build corresponding DeliveryOptions request, then click <b className="text-success">Get Options</b></li>
                                        <li>By <b>Request</b>: Click <b className="text-muted">Paste Example</b> button to get an example request, then click <b className="text-success">Get Options</b>. Feel free to change parameters in the request as needed.</li>
                                    </ol>
                                </p>
                                <p>See below the quick guide for request parameters.</p>
                                <p>
                                    Please reach out to <b>#plant-tech-support</b> if you have questions, suggestions or something is not working as expected.
                                </p>
                            </div>
                        )}
                    </>
                )}
            </CardBody>
        </Card>
    )
}

export default DeliveryOptionsCard