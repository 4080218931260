import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { getRequestByOrderCode } from "../api/TestDeliveryOptions";
import exampleJson from "../utils/exampleJson";

type Props = {
    cardProps?: any,
    onStartLoading: () => void,
    onStopLoading: () => void,
    onSetError: (error: string) => void,
    onClearError: () => void,
    onGetDeliveryOptions: (jsonInput: string) => void
}

const InputCard = (props: Props) => {
    const [orderCode, setOrderCode] = useState('');
    const [jsonInput, setJsonInput] = useState('');
    const [copyMessage, setCopyMessage] = useState<string | null>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
        }
    }, [jsonInput]);

    const handleOrderCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOrderCode(e.target.value);
    };

    const handleGetJson = async () => {
        props.onStartLoading()
        props.onClearError()
        try {
            const response = await getRequestByOrderCode(orderCode);
            setJsonInput(JSON.stringify(response, null, 2));
        } catch (err: any) {
            props.onSetError(err?.response?.data?.Message || "An error occurred while fetching the Delivery Options request.");
        } finally {
            props.onStopLoading()
        }
    };

    const handleCopyExampleJson = () => {
        setJsonInput(exampleJson);
        setCopyMessage('Example JSON has been copied to the input box');
        setTimeout(() => {
            setCopyMessage(null);
        }, 3000);
    };

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setJsonInput(e.target.value);
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
        }
    };

    return (
        <Card {...props.cardProps}>
            <CardHeader title="INPUT" {...props.cardProps}></CardHeader>
            <CardBody {...props.cardProps}>
                <div>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="SAL order code (optional)"
                            value={orderCode}
                            onChange={handleOrderCodeChange}
                        />
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={handleGetJson}
                        >
                            Get Request
                        </button>
                    </div>
                    <div className="input-group mb-3">
                        <div className="d-flex w-100">
                            <button
                                onClick={handleCopyExampleJson}
                                type="button"
                                className="btn btn-secondary mr-2"
                                style={{ flex: 1 }}
                            >
                                Paste Example
                            </button>
                            <button
                                onClick={() => props.onGetDeliveryOptions(jsonInput)}
                                type="button"
                                className="btn btn-success"
                                style={{ flex: 1 }}
                            >
                                Get Options
                            </button>
                        </div>
                    </div>
                    <textarea
                        ref={inputRef}
                        value={jsonInput}
                        onChange={handleInputChange}
                        className="form-control mb-10"
                        rows={10}
                        placeholder="Click Paste Example button to get working example ... or write/paste your own Delivery Options API request"
                        style={{ padding: '10px', fontSize: '11px', overflow: 'hidden', fontFamily: 'courier' }}
                    ></textarea>
                    {copyMessage && <div className="mt-2 text-success">{copyMessage}</div>}
                </div>
                <div className="p-5 alert-info rounded">
                    <p>Quick guide for the <b>Request parameters</b>:
                        <ul id="collapsibleContent">
                            <li><b>ChannelName</b>: photobox.fr / photobox.co.uk / hofmann.es / albelli.nl / etc as they are known in Ecom. Used for routing to correct production site. <b>Required</b></li>
                            <li><b>Brand</b>: corresponds to  the channel name without locale (e.g photobox, hofmann, albelli). Could be used in carrier selection rules to limit delivery options to certain brands. <b>Required</b></li>
                            <li><b>Address</b>: delivery address of the order.
                                <ul>
                                    <li><b>Country is required</b></li>
                                    <li>(optional) <b>Postcode</b> will be considered in carrier selection and shipping lead time.</li>
                                    <li>(optional) <b>Name</b> in the format "Testing <span className="keyWord">CODE</span>", where <span className="keyWord">CODE</span> is one of the known production site codes (as visible in the MyFactory UI - KHS, PAT, ELA, JONDO etc.)
                                        This allows to override routing rules, and direct order to the specified factory.</li>
                                </ul>
                            </li>
                            <li><b>Products</b>: array of products, each having a tag, product code, quantity and set of options
                                <ul>
                                    <li><b>Tag</b>: is a simple identifier of a product (single unique letter is OK) that will be echoed in package contents. Allows to identify how products are allocated between factories and packages</li>
                                    <li><b>Article Code</b>: (PAP number) is a generic identifier of a product. Together with set of options, it allows to map to SKU</li>
                                    <li><b>Options</b>: (set of names and values) allow to specify configuration of an ordered product. Must match with what is setup in Ecom catalog, as well as in Routing Rules and Packaging Calculator)
                                        <b> Pages: 1</b> is the bare minimum required option, that is valid for most of the products.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                </div>
            </CardBody>
        </Card>
    )
}

export default InputCard