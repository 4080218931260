import axios from "axios";
import { getConfig } from "../../modules/Config/Config";

export type TraceEvent = {
  id?: string,
  name?: string,
  source?: string,
  activityId?: string,
  parentActivityId?: string,
  traceId?: string,
  tags?: any,
  data?: string,
  eventType?: string,
  publishedAt?: string,
  storedAt?: string,
  expiresAt?: string,
  version?: string
}

export type GetTraceEventsResponse = {
  traceEvents: TraceEvent[]
}

async function getBusinessTracingEvents(traceId: string): Promise<TraceEvent[]> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.get<GetTraceEventsResponse>(
      `${backendConfig.url}/businesstracing/events?traceId=${traceId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(response => response.data)

    return response.traceEvents.map((apiEntry: any) => {
      const newEntry = {
        ...apiEntry
      }
      if (apiEntry.data) {
        newEntry.data = JSON.parse(apiEntry.data)
      }
      return newEntry
    });
  } catch (error) {
    console.error("Error in getBusinessTracingEvents:", error);
    throw error;
  }
}

export { getBusinessTracingEvents };
