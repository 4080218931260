import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls";
import ReactJson from 'react-json-view'
import { TraceEvent } from "../api/BusinessTracing";
import { Spinner } from "react-bootstrap";

type Props = {
    traceEvents: TraceEvent[] | null,
    onRefreshTrace: () => void,
    isLoading: boolean,
    cardProps?: any;
}

const BusinessTracingJsonCard = ({ traceEvents, onRefreshTrace, isLoading, cardProps }: Props) => {
    const simplifiedTraceEvents = (traceEvents) ? traceEvents.map(event => {
        return {
            name: event.name,
            source: event.source,
            publishedAt: event.publishedAt,
            tags: event.tags,
            data: event.data,
            fullTraceEvent: event
        }
    }) : []
    simplifiedTraceEvents.sort((left: any, right: any) => {
        const leftDate = new Date(left.fullTraceEvent.publishedAt)
        const rightDate = new Date(right.fullTraceEvent.publishedAt)
        return leftDate.valueOf() - rightDate.valueOf()
    })
    return (
        <Card {...cardProps}>
            <CardHeader title="DELIVERY OPTIONS TRACE" {...cardProps} />
            <CardBody {...cardProps}>
                {isLoading &&
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" variant="primary"></Spinner>
                        <div>Calculating...</div>
                    </div>}
                {!traceEvents && !isLoading &&
                    <div className="text-left p-5 alert-info rounded">
                        <p>No tracing information has been collected yet</p>
                        <p>It should be added automatically after a <b>Get Options</b> request has completed</p>
                        <p>If a <b>Get Options</b> call has been done and you suspect the trace is incomplete, you can <b>Refresh Trace</b></p>
                    </div>}
                {
                    traceEvents &&
                    <ReactJson src={simplifiedTraceEvents} theme="shapeshifter:inverted" name="TraceEvents" displayDataTypes={false} displayObjectSize={false} collapsed={2} />
                }
            </CardBody>
            <CardFooter {...cardProps}>
                <div className="d-flex justify-content-end">
                    <button
                        onClick={onRefreshTrace}
                        type="button"
                        className="btn btn-success"
                    >
                        Refresh Trace
                    </button>
                </div>
            </CardFooter>
        </Card>
    )
}

export default BusinessTracingJsonCard
