const exampleJson = `{
    "ChannelName": "photobox.co.uk",
    "Brand": "Photobox",
    "Locale": "GB",  
    "Address": {
      "CountryId": "GB",
      "Postcode": "EC1R 5EN",
      "ReceiverName": null
    },
    "OrderDate": null,
    "Products": [
      {
        "tag": "Mug",
        "articleCode": "PAP_720",
        "quantity": 1,
        "options": {
          "Pages": "1",
          "articleCode": "PAP_720"
        }
      },
      {
        "tag": "Cards",
        "articleCode": "PAP_940",
        "quantity": 10,
        "options": {
          "Pages": "10",
          "Paper": "Glossy",
          "articleCode": "PAP_940"
        }
      }
    ]
  }`;

export default exampleJson